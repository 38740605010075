<template>
    <iframe
        data-testid="balance-transfer-iframe"
        class="iframe"
        :id="id"
        :srcdoc="srcDoc"
        ref="iframe"
        @load="iframeLoaded"
    />
</template>

<script>
    import { logger } from '@/utils/logger'
    export default {
        name: 'BalanceTransferIframe',
        props: {
            id: {
                type: String,
                default: '',
            },
            srcDoc: {
                type: String,
                default: '',
            },
        },
        created: function () {
            window.document.addEventListener('lambda-iframe-success-event', this.successEventEmitter)
            window.document.addEventListener('lambda-iframe-failure-event', this.failureEventEmitter)
        },
        destroyed: function () {
            window.document.removeEventListener('lambda-iframe-success-event', this.successEventEmitter)
            window.document.removeEventListener('lambda-iframe-failure-event', this.failureEventEmitter)
        },
        methods: {
            iframeLoaded: function () {
                this.$logEvent('event_balance_transfer_iframe_loaded')
                logger.info('BalanceTransferIframe loaded')
            },
            successEventEmitter: function (successMessage) {
                logger.info(`Received lambda-iframe-success-event and emit balance-transfer-event success ${JSON.stringify(successMessage)}`)
                this.$emit('balance-transfer-success-event', successMessage)
            },
            failureEventEmitter: function (failureMessage) {
                logger.info(`Received lambda-iframe-failure-event and emit balance-transfer-event iframe-error ${JSON.stringify(failureMessage)}`)
                this.$emit('balance-transfer-failure-event', failureMessage)
            },
        },
    }
</script>

<style lang="scss" scoped>
    iframe {
        border: none;
        width: 100%;
        height: 415px; // this is the total px height for all inputs + active/hover effects + buttons
    }
</style>
