<template>
    <div
        v-if="loading"
        class="container"
    >
        <loading-indicator
            v-if="loading"
            :title="$t('global.loadingMessage.loading')"
        />
    </div>

    <div v-else>
        <page-header
            :title="currentScreen !== Screens.confirmation ? $t('pages.balanceTransfer.title') : null"
            show-back
            @onClose="handleBackNavigation"
            data-testid="balance-transfer-screen"
        />

        <div v-if="avenAccountStanding !== AvenAccountStanding.current">
            <feature-not-available />
        </div>

        <div v-else-if="errorMessage">
            <balance-transfer-error
                :error-title="errorTitle"
                :error-text="errorMessage"
            />
        </div>

        <div v-else-if="currentScreen === Screens.history">
            <div v-if="isPrimaryCard || isCoApplicantCard">
                <div v-if="existingBalanceTransfers && existingBalanceTransfers.size > 0">
                    <div class="container d-grid">
                        <button
                            data-testid="new-balance-transfer-button"
                            class="btn btn-primary mt-3"
                            @click="clickedNewBalanceTransfer"
                        >
                            {{ $t('components.balanceTransfer.listTransfers.cta') }}
                        </button>
                    </div>

                    <transition name="fade">
                        <div v-if="activeBalanceTransferAccountsEligibleForRecurrence && activeBalanceTransferAccountsEligibleForRecurrence.length">
                            <section-header>
                                {{ $t('components.balanceTransfer.linkedCards.sectionHeader') }}
                            </section-header>
                            <list-row
                                v-for="account in activeBalanceTransferAccountsEligibleForRecurrence"
                                :key="account.accountId"
                                :show-arrow="true"
                                :value="getAutoOnTextForAccount(account)"
                                :on="account.isAutoBalanceTransferEnabled"
                                value-class="text-gray-400"
                                @onClick="$router.push(RoutePaths.RECURRING_BT_SELECT_CARDS)"
                            >
                                <template #title>
                                    <balance-transfer-lender :lender="account.accountName" />
                                </template>
                            </list-row>
                        </div>
                    </transition>

                    <div
                        v-for="[header, transactions] of existingBalanceTransfers"
                        :key="header"
                    >
                        <section-header>
                            {{ header }}
                        </section-header>
                        <list-row
                            v-for="LatestTransaction in transactions"
                            :key="LatestTransaction.initiationDate"
                            :prefix="convertDateTimeFormat(LatestTransaction.initiationDate, { outputFormat: 'MMM DD' })"
                            :value="toFormattedUSD(LatestTransaction.amount)"
                            show-arrow
                            @onClick="handleRowClick(LatestTransaction.id)"
                        >
                            <template #title>
                                <balance-transfer-lender :lender="LatestTransaction.lender" />
                            </template>
                        </list-row>

                        <div
                            v-if="header === 'PENDING'"
                            class="text-muted small ms-3 me-3"
                        >
                            <span v-html="$t('components.balanceTransfer.pendingTransfer')" />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="text-center container">
                        <div
                            class="alert alert-warning text-start mt-2"
                            v-if="haveNotActivatedCardsBefore"
                            role="alert"
                        >
                            <span v-html="$t('pages.cashOut.cardNotActivatedMessage', { featureName: 'transfer' })" />
                            <a
                                class="text-start error-link"
                                @click.prevent="onClickCardActivationLink"
                            > Activate Now </a>
                        </div>
                        <img
                            src="@/assets/images/pages/settings/balanceTransfer.svg"
                            alt="No current balance transfers"
                            class="mb-3"
                        >
                        <h5
                            class="text-center fw-light my-1"
                            v-html="$t('components.balanceTransfer.empty.title')"
                        />

                        <p
                            class="text-muted mb-3"
                            v-html="$t('components.balanceTransfer.empty.subTitle')"
                        />

                        <div class="d-grid">
                            <button
                                data-testid="new-balance-transfer-button"
                                class="btn btn-primary"
                                @click="clickedNewBalanceTransfer"
                                :disabled="haveNotActivatedCardsBefore"
                            >
                                {{ $t('components.balanceTransfer.listTransfers.cta') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="text-center container">
                    <img
                        src="@/assets/images/pages/settings/balanceTransfer.svg"
                        alt="No current balance transfers"
                        class="m-auto"
                    >
                    <h5
                        class="text-center fw-light my-1"
                        v-html="$t('components.balanceTransfer.restricted.title')"
                    />
                    <div class="text-muted text-center mb-3">
                        <span v-html="$t('components.balanceTransfer.restricted.secondaryNotAllowed')" />
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="currentScreen === Screens.checkPassword">
            <SecuredPasswordCheck
                class="container"
                cta-event-name="click_balance_transfer_password_verification"
                forgot-password-event-name="click_balance_transfer_reset_passcode"
                @password-verified="passwordVerified"
            />
        </div>

        <div v-else-if="currentScreen === Screens.checkSsn">
            <SecuredSsnCheck
                class="container"
                cta-event-name="click_balance_transfer_ssn_4_verification"
                @ssn-last4-verified="ssnLast4Verified"
            />
        </div>

        <div v-else-if="currentScreen === Screens.iframe">
            <balance-transfer-iframe
                :src-doc="iframeSrc"
                @balance-transfer-success-event="onBalanceTransferSuccessEvent"
                @balance-transfer-failure-event="onBalanceTransferFailureEvent"
            />
        </div>

        <div v-else-if="currentScreen === Screens.summary">
            <div class="container">
                <form-container
                    @onSubmit="handleBT"
                    container-classes="bg-light mx-0 mt-2 px-3"
                    ref="btSummary"
                >
                    <div class="text-start">
                        <p v-html="$t('components.balanceTransfer.summary.subTitle', { amount: toFormattedUSD(this.amount), last4OfTargetCard: this.last4OfTargetCard })" />
                    </div>
                    <div class="card border-0 p-2 px-3 mb-3 mt-3 bg-light">
                        <div class="d-flex justify-content-between">
                            <div class="text-start">
                                <p class="mb-2">
                                    {{ $t('components.balanceTransfer.summary.transferAmountTitle', { last4OfTargetCard: this.last4OfTargetCard }) }}
                                </p>
                            </div>
                            <div class="text-end">
                                <p class="mb-2">
                                    {{ toFormattedUSD(this.amount) }}
                                </p>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="text-start">
                                <p class="mb-0">
                                    {{ $t('components.balanceTransfer.summary.transferFeeTitle', { fee: getBtFeePercent }) }}
                                </p>
                            </div>
                            <div class="text-end">
                                <p class="mb-0">
                                    {{ toFormattedUSD(getBtFeeAmount) }}
                                </p>
                            </div>
                        </div>
                        <hr class="w-100">
                        <div class="d-flex justify-content-between">
                            <div class="text-start">
                                <p class="fw-bold mb-0">
                                    {{ $t('components.balanceTransfer.summary.transferTotalTitle') }}
                                </p>
                            </div>
                            <div class="text-end">
                                <p class="fw-bold mb-0">
                                    {{ toFormattedUSD(getBtTotalAmountWithFee) }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        v-show="shouldShouldShowLoanChoices"
                        class="text-start"
                    >
                        <div class="text-center text-muted section-header mb-2">
                            {{ $t('components.amortizationLoanChoice.amortizationOptionsSectionHeader') }}
                        </div>
                        <div class="custom-control custom-radio custom-control-tight">
                            <input
                                type="radio"
                                id="amortizationLoanStandardOption"
                                name="amortizationLoanStandardOption"
                                class="custom-control-input"
                                :value="false"
                                v-model="shouldAmortize"
                            >
                            <label
                                class="custom-control-label custom-control-label-tight"
                                for="amortizationLoanStandardOption"
                            >{{
                                $t('components.amortizationLoanChoice.amortizationOptionsStandardText')
                            }}</label>
                            <p class="small text-muted">
                                {{ $t('components.amortizationLoanChoice.amortizationOptionsStandardSubText', { apr }) }}
                            </p>
                        </div>
                        <div class="custom-control custom-radio custom-control-tight mt-4">
                            <input
                                type="radio"
                                id="amortizationLoanTermOption"
                                name="amortizationLoanTermOption"
                                class="custom-control-input"
                                :value="true"
                                v-model="shouldAmortize"
                            >
                            <label
                                class="custom-control-label custom-control-label-tight"
                                for="amortizationLoanTermOption"
                            >{{
                                $t('components.amortizationLoanChoice.amortizationOptionsTermText')
                            }}</label>
                            <p
                                v-if="balanceTransferFee > 0"
                                class="small text-muted"
                            >
                                {{ $t('components.amortizationLoanChoice.amortizationOptionsTermSubTextWithFee', { fee: getBtFeePercent }) }}
                            </p>
                            <p
                                v-else
                                class="small text-muted"
                            >
                                {{ $t('components.amortizationLoanChoice.amortizationOptionsTermSubText') }}
                            </p>
                        </div>
                        <div
                            v-if="shouldAmortize"
                            class="ms-4 mt-4 custom-line-height"
                        >
                            <div class="custom-control custom-radio custom-control-tight mb-6">
                                <input
                                    type="radio"
                                    id="amortizationLoanTermOption1"
                                    name="amortizationLoanTermOption1"
                                    class="custom-control-input"
                                    :value="0"
                                    v-model="amortizationLoanTermSelectionId"
                                >
                                <label
                                    class="custom-control-label custom-control-label-tight w-100 mt-2"
                                    for="amortizationLoanTermOption1"
                                >
                                    <amortization-loan-choice
                                        :loan-term-in-months="parseFloat(amortizationLoanTermOptions[0].loanTermInMonths)"
                                        :monthly-installment-in-dollars="parseFloat(amortizationLoanTermOptions[0].monthlyInstallmentInDollars)"
                                        :fixed-finance-charges-per-month="parseFloat(amortizationLoanTermOptions[0].fixedFinanceChargesPerMonth)"
                                        :fixed-principal-per-month="parseFloat(amortizationLoanTermOptions[0].fixedPrincipalPerMonth)"
                                    />
                                </label>
                            </div>
                            <div class="custom-control custom-radio custom-control-tight">
                                <input
                                    type="radio"
                                    id="amortizationLoanTermOption2"
                                    name="amortizationLoanTermOption2"
                                    class="custom-control-input"
                                    :value="1"
                                    v-model="amortizationLoanTermSelectionId"
                                >
                                <label
                                    class="custom-control-label custom-control-label-tight w-100 mt-2"
                                    for="amortizationLoanTermOption2"
                                >
                                    <amortization-loan-choice
                                        :loan-term-in-months="parseFloat(amortizationLoanTermOptions[1].loanTermInMonths)"
                                        :monthly-installment-in-dollars="parseFloat(amortizationLoanTermOptions[1].monthlyInstallmentInDollars)"
                                        :fixed-finance-charges-per-month="parseFloat(amortizationLoanTermOptions[1].fixedFinanceChargesPerMonth)"
                                        :fixed-principal-per-month="parseFloat(amortizationLoanTermOptions[1].fixedPrincipalPerMonth)"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <base-button
                        class="mt-8"
                        data-testid="confirmBalanceTransfer"
                        :submitting="submitting"
                        button-classes="btn btn-primary"
                    >
                        {{ $t('components.balanceTransfer.summary.confirmTransferCta') }}
                    </base-button>
                </form-container>
            </div>
        </div>

        <div v-else-if="currentScreen === Screens.confirmation">
            <div
                class="container text-center"
                data-testid="balance-transfer-confirmation-container"
            >
                <img
                    src="@/assets/images/components/modal/balanceTransferConfirmation.svg"
                    :alt="$t('components.balanceTransfer.confirmation.title')"
                >

                <h5
                    class="text-center fw-light my-1"
                    v-html="$t('components.balanceTransfer.confirmation.title')"
                />

                <div class="text-muted text-center mb-3">
                    <span v-html="$t('components.balanceTransfer.confirmation.subTitle', { amount: toFormattedUSD(this.amount), last4OfTargetCard: this.last4OfTargetCard })" />
                </div>

                <div
                    v-show="shouldAmortize"
                    class="text-muted text-center mb-3"
                >
                    <span>For more details, you can <a
                        @click="getDocumentAndOpen('AccountAgreement')"
                        href="#"
                    >view your updated terms</a> which now include details on Fixed Monthly
                        Installments.</span>
                </div>

                <div class="d-grid">
                    <button
                        class="btn btn-secondary mb-2"
                        type="submit"
                        @click="onTransferOtherAccounts"
                    >
                        {{ $t('components.balanceTransfer.confirmation.transferOtherAccounts') }}
                    </button>
                </div>

                <div class="d-grid">
                    <button
                        class="btn btn-secondary mb-2"
                        type="submit"
                        @click="onConfirmationDone"
                    >
                        {{ $t('components.balanceTransfer.confirmation.doneCta') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import PageHeader from '@/components/PageHeader'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { logger } from '@/utils/logger'
    import generic from '@/utils/generic'
    import format from '@/mixins/format'
    import SectionHeader from '@/components/SectionHeader'
    import ListRow from '@/components/ListRow'
    import { AvenAccountStanding } from '@/store'
    import FeatureNotAvailable from '@/components/FeatureNotAvailable'
    import BalanceTransferLender from '@/components/BalanceTransferLender'
    import BalanceTransferError from '@/components/BalanceTransferError'
    import BalanceTransferIframe from '@/components/BalanceTransferIframe'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { i18n } from '@/utils/i18n'
    import axios from 'axios'
    import { navigation } from '@/mixins/navigation'
    import { RouteNames } from '@/routes/router'
    import FormContainer from '@/components/base/FormContainer'
    import BaseButton from '@/components/base/BaseButton'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { getAmortizationLoanTerms, getLegalDocumentDownload, postCreditCardBalanceTransfer, generateLegalDocument, requestBalanceTransferCancelation } from '@/services/api'
    import { AmortizationSource, MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS } from '@/data/constants'
    import AmortizationLoanChoice from '@/components/AmortizationLoanChoice'
    import { isSafari } from '@/utils/parseUserAgents'
    import { RoutePaths } from '@/routes/router'
    import { PATHS } from '@/utils/paths'
    import SecuredSsnCheck from '@/components/SecuredSsnCheck'
    import SecuredPasswordCheck from '@/components/SecuredPasswordCheck'

    // AWS Lambda iFrame Configuration
    const LAMBDA_CC_IFRAME_PATH = `/getBalanceTransferIFrame`
    const lambdaConfig = {
        baseURL: process.env.VUE_APP_LAMBDA_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    }
    const httpLambdaClient = axios.create(lambdaConfig)

    const Screens = {
        history: 0,
        checkPassword: 1,
        checkSsn: 2,
        iframe: 3,
        summary: 4,
        confirmation: 5,
    }

    export default {
        name: RouteNames.BALANCE_TRANSFER,
        components: {
            SecuredPasswordCheck,
            SecuredSsnCheck,
            AmortizationLoanChoice,
            PageHeader,
            LoadingIndicator,
            SectionHeader,
            ListRow,
            FeatureNotAvailable,
            BalanceTransferLender,
            BalanceTransferIframe,
            BalanceTransferError,
            FormContainer,
            BaseButton,
        },
        mixins: [format, navigation], //returnToRoot comes from navigation
        data: function () {
            return {
                loading: true,
                errorTitle: '',
                errorMessage: '',
                iframeSrc: '',
                submitting: false,
                successResponse: {},
                AvenAccountStanding,
                Screens,
                amount: null,
                last4OfTargetCard: null,
                balanceTransferId: null,
                currentScreen: Screens.history,
                shouldAmortize: false,
                amortizationLoanTermOptions: [
                    {
                        apr: null,
                        loanAmount: null,
                        loanTermInMonths: null,
                        monthlyInstallmentInDollars: null,
                        fixedFinanceChargesPerMonth: null,
                        fixedPrincipalPerMonth: null,
                        amortizationLoanId: null,
                    },
                    {
                        apr: null,
                        loanAmount: null,
                        loanTermInMonths: null,
                        monthlyInstallmentInDollars: null,
                        fixedFinanceChargesPerMonth: null,
                        fixedPrincipalPerMonth: null,
                        amortizationLoanId: null,
                    },
                ],
                amortizationLoanTermSelectionId: 0,
                RoutePaths,
                shouldShouldShowLoanChoices: this.enableAmortizationLoan,
            }
        },
        mounted: function () {
            this.$logEvent('view_balance_transfer')
            this.getBalanceTransfers()
            this.getBalanceTransferAccounts()
            this.shouldShouldShowLoanChoices = this.enableAmortizationLoan
        },
        computed: {
            ...mapState(['balanceTransfer']),
            ...mapGetters([
                'fullName',
                'isPrimaryCard',
                'isCoApplicantCard',
                'avenAccountStanding',
                'existingBalanceTransfers',
                'availableCredit',
                'apr',
                'last4CardNumber',
                'balanceTransferFee',
                'isWebView',
                'enableAmortizationLoan',
                'apr',
                'activeBalanceTransferAccountsEligibleForRecurrence',
                'haveNotActivatedCardsBefore',
                'totalPendingCashOutPlusBalanceTransfers',
            ]),
            getBtTotalAmountWithFee: function () {
                if (!this.amount) {
                    return 0
                }
                return this.amount * (1 + this.balanceTransferFee)
            },
            getBtFeePercent: function () {
                logger.info(`Balance Transfer Fee = ${this.balanceTransferFee}`)
                return this.toFormattedAprStripTrailingZerosAfterDecimalPoint(this.balanceTransferFee)
            },
            getBtFeeAmount: function () {
                return this.getBtTotalAmountWithFee - this.amount
            },
        },
        methods: {
            clickedNewBalanceTransfer() {
                this.currentScreen = Screens.checkPassword
            },
            passwordVerified() {
                this.currentScreen = Screens.checkSsn
            },
            ssnLast4Verified() {
                this.newBalanceTransfer()
            },
            handleRowClick(id) {
                this.$router.push({
                    name: RouteNames.BALANCE_TRANSFER_BY_ID,
                    params: {
                        id,
                    },
                })
            },
            getDocumentAndOpen: async function (docType) {
                this.loading = true

                logger.info(`Generating new legal doc: ${docType}...`)
                await generateLegalDocument(docType)
                logger.info(`Generating new legal doc: ${docType}...Done`)

                if (this.isWebView) {
                    logger.info(`native webview, get Account Agreement`)
                    const link = `/legal/document?docType=${docType}`
                    logger.info(`native webview, set window.location.href to ${link}`)
                    window.location.href = link
                } else {
                    logger.info(`browser, download account agreement`)
                    const response = await getLegalDocumentDownload(docType)
                    logger.info(`browser, download account agreement completed`)
                    const file = new Blob([response.data], { type: 'application/pdf' })
                    const fileURL = URL.createObjectURL(file)
                    if (isSafari()) {
                        window.location.href = fileURL
                    } else {
                        window.open(fileURL, '_blank')
                    }
                }
                this.loading = false
            },
            getBalanceTransfers: async function () {
                try {
                    // get experian account info
                    logger.info('getting existing balance transfers')
                    await this.$store.dispatch('getExistingBalanceTransfers')
                } catch (e) {
                    this.errorText = 'Could not get balance transfers'
                } finally {
                    this.loading = false
                }
            },
            getBalanceTransferAccounts: async function () {
                await this.$store.dispatch('getActiveBalanceTransferAccountsEligibleForRecurrence')
            },
            getAutoOnTextForAccount: function (account) {
                if (account.isAutoBalanceTransferEnabled) {
                    return 'Auto On'
                } else {
                    return ''
                }
            },
            convertDateTimeFormat: function (date, options) {
                return generic.convertDateTimeFormat(date, options)
            },
            newBalanceTransfer: async function () {
                this.$logEvent('click_button_new_balance_transfer')
                try {
                    this.loading = true
                    logger.info(`fetching iframe`)
                    const accessToken = appSessionStorage.getItem(localStorageKey.jwtToken)
                    let maxAllowedAmount = this.availableCredit
                    let maxAllowedAmountReason = i18n.tc('components.balanceTransfer.balanceTransferCreditLimitInfo')
                    if (maxAllowedAmount > MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS - this.totalPendingCashOutPlusBalanceTransfers) {
                        logger.info(
                            `Customer available credit (${maxAllowedAmount}), is higher than the daily max (${
                                MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS - this.totalPendingCashOutPlusBalanceTransfers
                            })`
                        )
                        maxAllowedAmount = MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS - this.totalPendingCashOutPlusBalanceTransfers
                        maxAllowedAmountReason = i18n.tc('components.balanceTransfer.balanceTransferDailyLimitInfo')
                    }
                    maxAllowedAmountReason += ' ' + i18n.tc('components.balanceTransfer.empty.subTitle')

                    const payload = {
                        token: accessToken,
                        isCsrPortal: false,
                        availableCreditLimit: maxAllowedAmount,
                        apr: this.apr,
                        last4AvenCard: this.last4CardNumber,
                        isPrimaryOrCoApplicant: this.isPrimaryCard || this.isCoApplicantCard,
                        maxAllowedAmountReason,
                    }

                    this.iframeSrc = (await httpLambdaClient.post(LAMBDA_CC_IFRAME_PATH, payload)).data
                    this.loading = false
                    this.currentScreen = Screens.iframe
                    this.errorMessage = ''
                } catch (e) {
                    logger.error(`error loading lambda iframe: ${e}`)
                }
            },
            onBalanceTransferSuccessEvent: async function (event) {
                // lambda communicates through the iFrame via events.
                // These are standardized to enable non-lambda originating events as well.
                this.$logEvent('event_balance_transfer_success', event.detail)
                logger.info(`balance transfer event succeeded ${JSON.stringify(event.detail)}`)
                this.errorMessage = ''

                this.successResponse = JSON.parse(event.detail.message)
                this.amount = this.successResponse.amount
                this.last4OfTargetCard = this.successResponse.last4OfAccount
                this.balanceTransferId = this.successResponse.balanceTransferId

                await this.tryGetAmortizationTerms()
                this.currentScreen = Screens.summary

                await this.getBalanceTransfers()
                await this.getBalanceTransferAccounts()
            },
            onBalanceTransferFailureEvent: async function (event) {
                // TODO: consider adding more detailed error messages
                this.$logEvent('event_balance_transfer_failed', event.detail)
                logger.info(`balance transfer event failed ${JSON.stringify(event.detail)}`)

                this.errorTitle = i18n.tc('components.balanceTransfer.error.title')

                switch (event.detail.eventName) {
                    case 'invalid-account-number-fail':
                    case 'validation-rtds-failure':
                        this.errorTitle = i18n.tc('components.balanceTransfer.error.invalidAccountNumberFail.title')
                        this.errorMessage = i18n.tc('components.balanceTransfer.error.invalidAccountNumberFail.subTitle')
                        break
                    case 'bt-forbidden-to-customer':
                        this.errorMessage = i18n.tc('components.balanceTransfer.error.btForbiddenToCustomer')
                        break
                    case 'invalid-amount-fail':
                        this.errorMessage = i18n.tc('components.balanceTransfer.error.invalidAmountFail')
                        break
                    case 'bank-not-supported':
                        this.errorMessage = i18n.tc('components.balanceTransfer.error.bankNotSupported')
                        break
                    case 'aci-submission-failed':
                        this.errorMessage = event.detail.error
                        break
                    case 'duplicate-request-error':
                        this.errorTitle = i18n.tc('components.balanceTransfer.error.duplicateRequestError.title')
                        this.errorMessage = event.detail.error
                        break
                    default:
                        this.errorMessage = i18n.tc('components.balanceTransfer.error.genericError')
                }
                logger.error(`balance transfer error message: ${this.errorMessage} title: ${this.errorTitle}`)
                this.currentScreen = Screens.history
            },
            onConfirmationDone: function () {
                this.shouldAmortize = false
                this.currentScreen = Screens.history
                this.errorMessage = ''
            },
            onTransferOtherAccounts: function () {
                this.shouldAmortize = false
                this.currentScreen = Screens.iframe
                this.errorMessage = ''
            },
            handleBT: async function () {
                this.$logEvent('click_credit_card_balance_transfer_amount_confirmation', {
                    amount: this.amount,
                    amountPlusFee: this.getBtTotalAmountWithFee.toFixed(2),
                })
                this.submitting = true

                try {
                    const response = await postCreditCardBalanceTransfer(
                        this.balanceTransferId,
                        this.amortizationLoanTermOptions[this.amortizationLoanTermSelectionId].amortizationLoanId,
                        this.shouldAmortize
                    )

                    if (!response.data.success) {
                        logger.error(`postCreditCardBalanceTransfer failed with ${response.data.error}`)
                        if (response.data.error) {
                            this.errorText = response.data.error
                        } else {
                            this.errorText = i18n.t('components.balanceTransfer.error.genericError')
                        }
                        this.currentScreen = Screens.history
                        return false
                    }

                    this.currentScreen = Screens.confirmation
                    return true
                } catch (error) {
                    if (error.response?.status === 401) {
                        // short term fix -- see TODO in ApiErrorHandler to not redirect on 401
                        this.errorText = i18n.t('components.balanceTransfer.error.genericError')
                        this.currentScreen = Screens.history
                    } else {
                        ApiErrorHandler(error)
                    }
                    return false
                } finally {
                    this.submitting = false
                }
            },
            tryGetAmortizationTerms: async function () {
                try {
                    if (this.enableAmortizationLoan) {
                        this.loading = true
                        const amortizationLoanTermsResponse = await getAmortizationLoanTerms(this.amount, AmortizationSource.CREDIT_CARD_BALANCE_TRANSFER)
                        if (!amortizationLoanTermsResponse.data.success) {
                            this.shouldShouldShowLoanChoices = false
                        } else {
                            this.amortizationLoanTermOptions = amortizationLoanTermsResponse.data.payload.amortizationLoanOptions
                        }
                    } else {
                        this.shouldShouldShowLoanChoices = this.enableAmortizationLoan
                    }
                } catch (e) {
                    logger.error(`getAmortizationTerms failed`, null, e)
                    this.shouldShouldShowLoanChoices = false
                    this.amortizationLoanId = null
                } finally {
                    this.loading = false
                }
            },
            handleBackNavigation: async function () {
                if (this.currentScreen === Screens.summary) {
                    logger.info(`Customer is leaving BT after viewing amortization options. Marking this BT as 'Canceled'`)
                    try {
                        // Since this BT was not submitted, it is guaranteed to be canceled.
                        await requestBalanceTransferCancelation(this.balanceTransferId)
                    } catch (e) {
                        logger.error(`Canceling BT with Id: ${this.balanceTransferId} failed. Customer navigated away from BT screen after viewing amortization options but before submitting BT.`)
                    }
                    return await this.returnToRoot()
                } else if (this.currentScreen === Screens.history) {
                    return await this.returnToRoot()
                } else {
                    this.currentScreen = Screens.history
                }
            },
            onClickCardActivationLink: function () {
                if (this.isWebView && !this.isSingleWebView) {
                    window.location.href = PATHS.MOBILE_REDIRECT.CARD_ACTIVATION
                } else {
                    this.$router.push({
                        name: 'CardActivation',
                    })
                }
            },
        },
    }
</script>

<style scoped>
    .list-group-flush > .list-group-item {
        border-width: 0;
    }
    .custom-line-height {
        line-height: 1;
    }
    .error-link {
        text-decoration: underline;
        color: #cf8100;
    }
</style>
