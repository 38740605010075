<template>
    <span class="balance-transfer-lender-container">
        <span
            class="start-chunk overflow-hidden"
            v-html="lenderChunks.start"
        />
        <span
            class="end-chunk"
            v-html="lenderChunks.end"
        />
    </span>
</template>

<script>
    /**
     * This component is an utility that truncates the string in the middle of the text instead of
     * at the end, so that we can preserve the last 9 characters that, for a transfer that looks
     * like: `Carmax (***5387)` corresponds to the last `(***5387)`.
     */
    export default {
        name: 'BalanceTransferLender',
        props: {
            lender: {
                type: String,
                required: true,
            },
        },
        computed: {
            lenderChunks() {
                return {
                    start: this.lender.substr(0, this.lender.length - 9),
                    end: this.lender.substr(this.lender.length - 9),
                }
            },
        },
    }
</script>

<style scoped>
    @import '../styles/components/balanceTransferLender.scss';
</style>
