<template>
    <div>
        <h5
            class="text-center mx-6 mb-4"
            v-html="$t('components.securedSsnCheck.title')"
        />
        <form-container
            ref="ssnForm"
            @onSubmit="verifyLast4Ssn"
            data-testid="ssn-form"
        >
            <form-field-ssn
                ref="ssnField"
                name="last4Ssn"
                class="mb-3"
                v-model="last4Ssn"
                :placeholder="$t('pages.cashOut.ssnPlaceholder')"
                last-four-only
                data-testid="ssn-form-last4-input"
            />

            <base-button
                type="submit"
                :submitting="submitting"
                :disabled="last4Ssn.length !== 4"
                data-testid="ssn-form-submit-button"
            >
                {{ $t('global.cta.continue') }}
            </base-button>
        </form-container>
    </div>
</template>

<script>
    import { i18n } from '@/utils/i18n'
    import { postVerifyLast4Ssn } from '@/services/api'
    import { logger } from '@/utils/logger'
    import BaseButton from '@/components/base/BaseButton'
    import FormContainer from '@/components/base/FormContainer'
    import FormFieldSsn from '@/components/base/FormFieldSsn'

    export default {
        name: 'SecuredSsnCheck',
        components: {
            FormContainer,
            FormFieldSsn,
            BaseButton,
        },
        props: {
            ctaEventName: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                last4Ssn: '',
                submitting: false,
                errorMessage: '',
                LAST4_SSN_VERIFIED: 'ssn-last4-verified',
            }
        },
        watch: {
            errorMessage: function (val) {
                this.$refs.ssnForm.applyGeneralError(val)
            },
        },
        methods: {
            verifyLast4Ssn: async function () {
                this.$logEvent(this.ctaEventName, { last4Ssn: this.last4Ssn })
                this.submitting = true

                try {
                    const response = await postVerifyLast4Ssn(this.last4Ssn)

                    if (response.data.error === 'INCORRECT_SSN_ERROR') {
                        logger.info('Could not verify ssn last 4')
                        this.errorMessage = i18n.t('components.securedSsnCheck.ssnInvalid')
                        return
                    }

                    this.$emit(this.LAST4_SSN_VERIFIED, this.last4Ssn)
                } catch (error) {
                    this.errorMessage = i18n.t('global.errors.generic')
                    return false
                } finally {
                    this.submitting = false
                }
            },
        },
    }
</script>

<style scoped></style>
