<template>
    <div>
        <h5
            class="text-center mb-4"
            v-html="$t('components.securedPasswordCheck.title')"
        />
        <form-container
            class="mb-3"
            ref="passwordForm"
            data-testid="password-form"
            @onSubmit="passwordVerification"
        >
            <form-field
                ref="passwordInput"
                v-model="password"
                name="password"
                input-type="password"
                class="mb-2"
                validation="required"
                validation-mode="lazy"
                :placeholder="$t('pages.passwordLogin.placeHolder')"
                data-testid="password-form-password-input"
            />
            <base-button
                type="submit"
                :submitting="submitting"
                data-testid="password-form-submit-button"
            >
                {{ $t('global.cta.continue') }}
            </base-button>
        </form-container>
        <base-button
            button-classes="btn btn-tertiary"
            @click="forgotPassword"
            data-testid="forgot-password-button"
        >
            {{ $t('global.cta.forgotPassword') }}
        </base-button>
    </div>
</template>

<script>
    import { verifyPassword } from '@/services/avenAppApi'
    import { i18n } from '@/utils/i18n'
    import FormContainer from '@/components/base/FormContainer'
    import FormField from '@/components/base/FormField'
    import BaseButton from '@/components/base/BaseButton'
    import { logger } from '@/utils/logger'
    import { RouteNames } from '@/routes/router'

    export default {
        name: 'SecuredPasswordCheck',
        components: {
            FormContainer,
            FormField,
            BaseButton,
        },
        props: {
            ctaEventName: {
                type: String,
                required: true,
            },
            forgotPasswordEventName: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                password: '',
                submitting: false,
                errorMessage: '',
                // events emitted to parent
                PASSWORD_VERIFIED: 'password-verified',
                RouteNames,
            }
        },
        watch: {
            errorMessage: function (val) {
                this.$refs.passwordForm.applyGeneralError(val)
            },
        },
        methods: {
            passwordVerification: async function () {
                const isValid = await this.$refs.passwordForm.$refs.observer.validate()
                if (!isValid) {
                    logger.info('password field validation failed')
                    return
                }
                try {
                    this.$logEvent(this.ctaEventName)
                    this.submitting = true
                    const response = await verifyPassword(this.password)
                    this.$refs.passwordForm.clearErrorMessage()
                    const data = response.data

                    if (!data.success) {
                        logger.info('Could not verify password')
                        this.errorMessage = i18n.t('global.errors.generic')
                        return
                    }

                    this.$emit(this.PASSWORD_VERIFIED, this.password)
                } catch (error) {
                    if (error.response?.status === 401) {
                        if (error.response.data.error === 'INCORRECT_PASSCODE_ERROR') {
                            this.errorMessage = i18n.t('components.securedPasswordCheck.incorrectPassword')
                        } else {
                            this.errorMessage = i18n.t('global.errors.generic')
                        }
                    } else {
                        this.errorMessage = i18n.t('global.errors.generic')
                    }
                    this.password = ''
                    this.$refs.passwordInput.setFocus()
                } finally {
                    this.submitting = false
                }
            },
            forgotPassword: function () {
                this.$logEvent(this.forgotPasswordEventName)
                this.$router.push({
                    name: RouteNames.SET_PASSWORD,
                    params: {
                        resetDuringSecureFeature: true,
                    },
                })
            },
        },
    }
</script>

<style scoped></style>
