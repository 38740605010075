<template>
    <div class="container">
        <div class="text-center">
            <img
                src="@/assets/error-icon.svg"
                :alt="$t('components.balanceTransfer.error.title')"
            >
            <h5
                class="fw-light mb-2"
                v-html="errorTitle"
            />
        </div>

        <div class="text-muted text-center mb-4">
            <span v-html="errorText" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BalanceTransferError',
        props: {
            errorTitle: {
                type: String,
            },
            errorText: {
                type: String,
            },
        },
    }
</script>
